import React, { useContext } from 'react';
import TemplateGrid from "./TemplateGrid";
import {GlobalStateContext} from "../Context/GlobalStateContext";
import AuthContext from "../Context/AuthContext";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const {userInfo, loading, error} = useContext(GlobalStateContext);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!user) {
        navigate('/login');
        return;
    }

    return (
        <div className="container">
            <h1>Dashboard</h1>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {loading ? (
                <p>Loading user data... <span className="spinner"></span></p>
            ) : (
                userInfo ? (
                    <div>
                        <h2>Welcome, {user.username}!</h2>
                    </div>
                ) : (
                    <p>No user data available.</p>
                )
            )}
            <TemplateGrid />
        </div>
    );
};

export default Dashboard;
