import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from './Context/AuthContext';
import {GlobalStateContext} from "./Context/GlobalStateContext";

const Register = () => {
    const {serverUrl} = useContext(GlobalStateContext)
    const [registerData, setRegisterData] = useState({
        registerUsername: '',
        registerMail: '',
        registerPassword: ''
    });
    const [focus, setFocus] = useState({
        registerUsername: false,
        registerMail: false,
        registerPassword: false
    });

    const [errors, setErrors] = useState({
        registerUsername: false,
        registerMail: false,
        registerPassword: false
    });

    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const {registerUsername, registerMail, registerPassword} = registerData;
    const onChange = (e) => setRegisterData({...registerData, [e.target.name]: e.target.value});

    const handleFocus = (name) => setFocus({ ...focus, [name]: true });

    const handleBlur = (name) => {
        if (!registerData[name]) {
            setFocus({...focus, [name]: false});
        }
        validateField(name, registerData[name]);
    };

    const validateField = (name, value) => {
        let isValid = true;

        if (!value) {
            console.log(`Validation failed for ${name}: empty value`);
            setErrors({...errors, [name]: true});
            return false;
        }

        if (name === 'registerMail') {
            isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
            if (!isValid) console.log('Invalid email format');
        } else if (name === 'registerPassword') {
            isValid = value.length >= 6;
            if (!isValid) console.log('Password must be at least 6 characters');
        } else if (name === 'registerUsername') {
            isValid = value.length >= 3;
            if (!isValid) console.log('Username must be at least 3 characters');
        }

        setErrors({...errors, [name]: !isValid});
        return isValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log('Register button clicked');

        // Debugging: log the form data
        console.log('Form data:', registerData);

        // Validate all fields
        const isUsernameValid = validateField('registerUsername', registerData.registerUsername);
        const isMailValid = validateField('registerMail', registerData.registerMail);
        const isPasswordValid = validateField('registerPassword', registerData.registerPassword);

        console.log('Validation Results:', {isUsernameValid, isMailValid, isPasswordValid});

        // Only submit if all fields are valid
        if (isUsernameValid && isMailValid && isPasswordValid) {
            try {
                const response = await axios.post(serverUrl + '/auth/register', registerData, {
                    headers: {'Content-Type': 'application/json'}
                });
                console.log('Registration successful', response.data);
                await login({loginMail: registerMail, loginPassword: registerPassword});
            } catch (error) {
                console.error('Registration Error:', error);
            }
        } else {
            console.error('Validation Errors:', errors);
        }
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-dialog">
                <form onSubmit={e => onSubmit(e)} autoComplete="off">
                    <h1>Register</h1>
                    <div className="auth-input">
                        <input
                            className={`input ${errors.username ? 'invalid' : ''}`}
                            autoComplete="off"
                            type="text"
                            name="registerUsername"
                            value={registerUsername}
                            onChange={onChange}
                            onFocus={() => handleFocus('registerUsername')}
                            onBlur={() => handleBlur('registerUsername')}
                            required
                        />
                        <label className={`input-label ${focus.username || registerUsername ? 'active' : ''}`}>Username:</label>
                    </div>
                    <div className="auth-input">
                        <input
                            className={`input ${errors.email ? 'invalid' : ''}`}
                            autoComplete="off"
                            type="email"
                            name="registerMail"
                            value={registerMail}
                            onChange={onChange}
                            onFocus={() => handleFocus('registerMail')}
                            onBlur={() => handleBlur('registerMail')}
                            required
                        />
                        <label className={`input-label ${focus.email || registerMail ? 'active' : ''}`}>Mail:</label>
                    </div>
                    <div className="auth-input">
                        <input
                            className={`input ${errors.password ? 'invalid' : ''}`}
                            autoComplete="off"
                            type="password"
                            name="registerPassword"
                            value={registerPassword}
                            onChange={onChange}
                            onFocus={() => handleFocus('registerPassword')}
                            onBlur={() => handleBlur('registerPassword')}
                            required
                        />
                        <label className={`input-label ${focus.password || registerPassword ? 'active' : ''}`}>Password:</label>
                    </div>

                    <button className="auth-button" type="submit">Register</button>
                </form>
            </div>
        </div>
    );
};

export default Register;
